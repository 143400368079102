import React, { useState } from "react"
import { graphql } from "gatsby"
import emailjs from "emailjs-com"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Modal } from "react-bootstrap"
import Content from "../components/Content"
import Header from "../components/Header"
import AccordionGreen from "../components/AccordionGreen"
import Footer from "../components/Footer/Footer"
import Button from "../components/Button"
import Seo from "../components/SEO"

const Partners = ({ data }) => {
  const [email, setEmail] = useState("")
  const [name, setName] = useState("")
  const [message, setMessage] = useState("")
  const [companyName, setCompanyName] = useState("")
  const [contactVisible, contactSetVisible] = useState(false)
  const [confirmationVisible, confirmationSetVisible] = useState(false)
  const {
    allContentfulContentArea,
    allContentfulContactPage,
    allContentfulPartnersPage,
    allContentfulLandingPageMain,
    allContentfulTogglesPartners,
    allContentfulHeader,
    allContentfulSeo,
  } = data
  const handleSubmit = e => {
    e.preventDefault()
    emailjs
      .sendForm(
        "service_qkbep48",
        "template_vo1uex8",
        e.target,
        "user_H1giHzeT00K7WW4tInQOn"
      )
      .then(
        result => {
          console.log(result)
        },
        error => {
          console.log(error.text)
        }
      )
    emailjs
      .sendForm(
        "service_qkbep48",
        "template_8hke6d4",
        e.target,
        "user_H1giHzeT00K7WW4tInQOn"
      )
      .then(
        result => {
          console.log(result)
        },
        error => {
          console.log(error.text)
        }
      )
    handleClose()
    handleShowConfirmation()
    setEmail("")
    setName("")
    setMessage("")
    setCompanyName("")
  }

  const handleShow = () => contactSetVisible(true)
  const handleClose = () => contactSetVisible(false)
  const handleShowConfirmation = () => confirmationSetVisible(true)
  const handleCloseConfirmation = () => confirmationSetVisible(false)

  const handleSubmitEmail = e => {
    e.preventDefault()
    if (e.target["user_email"].value.length > 1) {
      handleShow()
    }
  }
  return (
    <div>
      <Seo
        title={allContentfulSeo.nodes[3].title}
        description={allContentfulSeo.nodes[3].description}
        keywords={allContentfulSeo.nodes[3].keywords}
      />
      <Header />
      <section className="contact-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6 contact-tagline">
              <h3 className="headings-of-all">
                {allContentfulHeader.nodes[0].btnThree}
              </h3>
              <div>
                <h2>{allContentfulContactPage.nodes[0].tagline}</h2>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 contct-form-area">
              <div>
                <img
                  style={{
                    marginTop: "20%",
                  }}
                  src={
                    allContentfulPartnersPage.nodes[0].partnersImage.file.url
                  }
                  alt={allContentfulPartnersPage.nodes[0].partnersImage.title}
                  className="img"
                />
              </div>
              <center>
                <section
                  class="flexbox"
                  style={{
                    width: "70%",
                    display: "table",
                    marginTop: "30%",
                    paddingTop: "0%",
                    marginBottom: "0%",
                    paddingBottom: "0%",
                    backgroundColor: "#e9ecef",
                  }}
                >
                  <div
                    className="form-control"
                    style={{
                      display: "table-cell",
                      width: "100%",
                      paddingLeft: "0%",
                      marginLeft: "0%",
                      paddingRight: "0%",
                      marginRight: "0%",
                      marginTop: "0%",
                      paddingTop: "0%",
                      backgroundColor: "#e9ecef",
                    }}
                  >
                    <div
                      className="input-group"
                      style={{
                        width: "100%",
                        paddingLeft: "0%",
                        marginLeft: "0%",
                        paddingRight: "0%",
                        marginRight: "0%",
                        backgroundColor: "#e9ecef",
                      }}
                    >
                      <form
                        onSubmit={handleSubmitEmail}
                        style={{
                          width: "100%",
                        }}
                      >
                        <label className="sr-only">EMAIL</label>
                        <input
                          style={{
                            backgroundColor: "white",
                            width: "100%",
                            border: "none",
                            borderRadius: "0px",
                            marginBottom: "7px",
                          }}
                          placeholder={
                            allContentfulPartnersPage.nodes[0].contactEmailText
                          }
                          className="form-control"
                          value={email}
                          type="email"
                          name="user_email"
                          onChange={e => setEmail(e.currentTarget.value)}
                        />

                        <Button
                          style={{
                            zIndex: "0",
                            width: "auto",
                          }}
                          className="btn-purple bigger-btn"
                          type="submit"
                          text={allContentfulPartnersPage.nodes[0].buttonText}
                        ></Button>
                      </form>
                    </div>
                  </div>
                </section>
              </center>
            </div>
          </div>
        </div>
      </section>
      <section
        className="contact-section container contact-mat"
        style={{
          marginTop: "0%",
          paddingTop: "0%",
        }}
      >
        <div
          className="row contact-info container "
          style={{
            marginTop: "0%",
            paddingTop: "0%",
          }}
        >
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 row align-items-center">
            <img
              src={allContentfulContactPage.nodes[0].phoneIcon.file.url}
              alt={allContentfulContactPage.nodes[0].dublinOfficeNum}
            />
            <h5>{allContentfulContactPage.nodes[0].dublinOfficeNum}</h5>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 row align-items-center">
            <img
              src={allContentfulContactPage.nodes[0].emailIcon.file.url}
              alt={allContentfulContactPage.nodes[0].tagline}
            />
            <h5>{allContentfulContactPage.nodes[0].email}</h5>
          </div>
        </div>
        <div className="gif">
          <img
            src={allContentfulContactPage.nodes[0].gif.file.url}
            alt="Contact GIF"
          />
        </div>
      </section>

      <div className="section-padding-2">
        <Content
          main1={allContentfulContentArea.nodes[2].textMain1.textMain1}
          main2={allContentfulContentArea.nodes[2].textMain2.textMain2}
          side1={allContentfulContentArea.nodes[2].textSide1.textSide1}
          side2={allContentfulContentArea.nodes[2].textSide2.textSide2}
          className="gr-bg"
        />
      </div>
      <section className="section-padding d-flex">
        <div className="container">
          <div>
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div>
                  {allContentfulTogglesPartners.nodes.map((v, i) => (
                    <AccordionGreen
                      title={v.title}
                      content={documentToReactComponents(
                        JSON.parse(v.description.raw)
                      )}
                      key={i}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        style={{ marginTop: "10%", height: "100%" }}
        show={contactVisible}
        onHide={() => contactSetVisible(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#243671" }}>
            {allContentfulLandingPageMain.nodes[0].contactPopupTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <center>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label className="sr-only">Email</label>
                <input
                  style={{
                    width: "80%",
                    marginTop: "4%",
                    backgroundColor: "white",
                    borderColor: "#f0f3f7",
                  }}
                  placeholder={email}
                  className="form-control"
                  value={email}
                  type="email"
                  name="user_email"
                  disabled
                />
              </div>
              <div className="form-group">
                <label className="sr-only">Full name</label>
                <input
                  style={{
                    width: "80%",
                    marginTop: "4%",
                    borderColor: "#b0b0b0",
                  }}
                  placeholder={
                    allContentfulLandingPageMain.nodes[0].contactPopupName
                  }
                  value={name}
                  type="text"
                  name="user_name"
                  onChange={e => setName(e.currentTarget.value)}
                />
              </div>
              <div className="form-group">
                <label className="sr-only">Company name</label>
                <input
                  style={{
                    width: "80%",
                    marginTop: "2%",
                    borderColor: "#b0b0b0",
                  }}
                  placeholder={
                    allContentfulLandingPageMain.nodes[0].contactPopupCompany
                  }
                  value={companyName}
                  type="companyName"
                  name="company_name"
                  onChange={e => setCompanyName(e.currentTarget.value)}
                />
              </div>
              <div className="form-group">
                <label className="sr-only">Message</label>
                <textarea
                  style={{
                    width: "80%",
                    marginTop: "4%",
                    borderRadius: "6px",
                    borderColor: "#b0b0b0",
                  }}
                  placeholder={
                    "  " +
                    allContentfulLandingPageMain.nodes[0].contactPopupMessage
                  }
                  value={message}
                  type="email"
                  name="user_message"
                  onChange={e => setMessage(e.currentTarget.value)}
                />
              </div>
              <div className="form-group">
                <label className="sr-only">Page</label>
                <input value="Partners Page" type="hidden" name="page" />
              </div>
              <button className="btn btn-purple" type="submit">
                {allContentfulLandingPageMain.nodes[0].contactPopupButton}
              </button>
            </form>
          </center>
        </Modal.Body>
      </Modal>

      <Modal
        style={{ marginTop: "15%", height: "100%" }}
        show={confirmationVisible}
        onHide={() => confirmationSetVisible(false)}
      >
        <Modal.Body
          style={{
            fontSize: "25px",
            backgroundColor: "#3e812c",
            color: "white",
          }}
        >
          {allContentfulLandingPageMain.nodes[0].contactConfirmationPopup}
          <button
            onClick={handleCloseConfirmation}
            class="close"
            aria-label="Close"
            type="submit"
            style={{ opacity: "1" }}
          >
            <span
              style={{ fontSize: "40px", color: "white", opacity: "1" }}
              aria-hidden="true"
            >
              &times;
            </span>
          </button>
        </Modal.Body>
      </Modal>

      <Footer />
    </div>
  )
}

export default Partners

export const query = graphql`
  query {
    allContentfulPartnersPage {
      nodes {
        partnersImage {
          file {
            url
          }
        }
        contactEmailText
        buttonText
      }
    }

    allContentfulContactPage {
      nodes {
        dublinOfficeNum
        headQuartersNum
        email
        tagline
        phoneIcon {
          file {
            url
          }
        }
        emailIcon {
          file {
            url
          }
        }
        gif {
          file {
            url
          }
        }
      }
    }

    allContentfulLandingPageMain {
      nodes {
        contactPopupTitle
        contactPopupName
        contactPopupCompany
        contactPopupMessage
        contactConfirmationPopup
        contactPopupButton
      }
    }
    allContentfulContentArea(sort: { fields: createdAt }) {
      nodes {
        textMain1 {
          textMain1
        }
        textMain2 {
          textMain2
        }
        textSide1 {
          textSide1
        }
        textSide2 {
          textSide2
        }
      }
    }
    allContentfulTogglesPartners(sort: { fields: createdAt, order: DESC }) {
      nodes {
        title
        description {
          raw
        }
      }
    }
    allContentfulHeader {
      nodes {
        btnThree
      }
    }
    allContentfulSeo(sort: { fields: createdAt }) {
      nodes {
        title
        description
        keywords
      }
    }
  }
`
